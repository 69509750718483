[data-rk] .iekbcc0 {
  box-sizing: border-box;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: normal;
}

[data-rk] .iekbcc1 {
  list-style: none;
}

[data-rk] .iekbcc2 {
  quotes: none;
}

[data-rk] .iekbcc2:before, [data-rk] .iekbcc2:after {
  content: "";
}

[data-rk] .iekbcc3 {
  border-collapse: collapse;
  border-spacing: 0;
}

[data-rk] .iekbcc4 {
  appearance: none;
}

[data-rk] .iekbcc5 {
  outline: none;
}

[data-rk] .iekbcc5::placeholder {
  opacity: 1;
}

[data-rk] .iekbcc6 {
  color: inherit;
  background-color: #0000;
}

[data-rk] .iekbcc7:disabled {
  opacity: 1;
}

[data-rk] .iekbcc7::-ms-expand {
  display: none;
}

[data-rk] .iekbcc8::-ms-clear {
  display: none;
}

[data-rk] .iekbcc8::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[data-rk] .iekbcc9 {
  cursor: pointer;
  text-align: left;
  background: none;
}

[data-rk] .iekbcca {
  color: inherit;
  text-decoration: none;
}

[data-rk] .ju367v0 {
  align-items: flex-start;
}

[data-rk] .ju367v2 {
  align-items: flex-end;
}

[data-rk] .ju367v4 {
  align-items: center;
}

[data-rk] .ju367v6 {
  display: none;
}

[data-rk] .ju367v8 {
  display: block;
}

[data-rk] .ju367va {
  display: flex;
}

[data-rk] .ju367vc {
  display: inline;
}

[data-rk] .ju367ve {
  align-self: flex-start;
}

[data-rk] .ju367vf {
  align-self: flex-end;
}

[data-rk] .ju367vg {
  align-self: center;
}

[data-rk] .ju367vh {
  background-size: cover;
}

[data-rk] .ju367vi {
  border-radius: 1px;
}

[data-rk] .ju367vj {
  border-radius: 6px;
}

[data-rk] .ju367vk {
  border-radius: 10px;
}

[data-rk] .ju367vl {
  border-radius: 13px;
}

[data-rk] .ju367vm {
  border-radius: var(--rk-radii-actionButton);
}

[data-rk] .ju367vn {
  border-radius: var(--rk-radii-connectButton);
}

[data-rk] .ju367vo {
  border-radius: var(--rk-radii-menuButton);
}

[data-rk] .ju367vp {
  border-radius: var(--rk-radii-modal);
}

[data-rk] .ju367vq {
  border-radius: var(--rk-radii-modalMobile);
}

[data-rk] .ju367vr {
  border-radius: 25%;
}

[data-rk] .ju367vs {
  border-radius: 9999px;
}

[data-rk] .ju367vt {
  border-style: solid;
}

[data-rk] .ju367vu {
  border-width: 0;
}

[data-rk] .ju367vv {
  border-width: 1px;
}

[data-rk] .ju367vw {
  border-width: 2px;
}

[data-rk] .ju367vx {
  border-width: 4px;
}

[data-rk] .ju367vy {
  cursor: pointer;
}

[data-rk] .ju367vz {
  cursor: none;
}

[data-rk] .ju367v10 {
  pointer-events: none;
}

[data-rk] .ju367v11 {
  pointer-events: all;
}

[data-rk] .ju367v12 {
  min-height: 8px;
}

[data-rk] .ju367v13 {
  min-height: 44px;
}

[data-rk] .ju367v14 {
  flex-direction: row;
}

[data-rk] .ju367v15 {
  flex-direction: column;
}

[data-rk] .ju367v16 {
  font-family: var(--rk-fonts-body);
}

[data-rk] .ju367v17 {
  font-size: 12px;
  line-height: 18px;
}

[data-rk] .ju367v18 {
  font-size: 13px;
  line-height: 18px;
}

[data-rk] .ju367v19 {
  font-size: 14px;
  line-height: 18px;
}

[data-rk] .ju367v1a {
  font-size: 16px;
  line-height: 20px;
}

[data-rk] .ju367v1b {
  font-size: 18px;
  line-height: 24px;
}

[data-rk] .ju367v1c {
  font-size: 20px;
  line-height: 24px;
}

[data-rk] .ju367v1d {
  font-size: 23px;
  line-height: 29px;
}

[data-rk] .ju367v1e {
  font-weight: 400;
}

[data-rk] .ju367v1f {
  font-weight: 500;
}

[data-rk] .ju367v1g {
  font-weight: 600;
}

[data-rk] .ju367v1h {
  font-weight: 700;
}

[data-rk] .ju367v1i {
  font-weight: 800;
}

[data-rk] .ju367v1j {
  gap: 0;
}

[data-rk] .ju367v1k {
  gap: 1px;
}

[data-rk] .ju367v1l {
  gap: 2px;
}

[data-rk] .ju367v1m {
  gap: 3px;
}

[data-rk] .ju367v1n {
  gap: 4px;
}

[data-rk] .ju367v1o {
  gap: 5px;
}

[data-rk] .ju367v1p {
  gap: 6px;
}

[data-rk] .ju367v1q {
  gap: 8px;
}

[data-rk] .ju367v1r {
  gap: 10px;
}

[data-rk] .ju367v1s {
  gap: 12px;
}

[data-rk] .ju367v1t {
  gap: 14px;
}

[data-rk] .ju367v1u {
  gap: 16px;
}

[data-rk] .ju367v1v {
  gap: 18px;
}

[data-rk] .ju367v1w {
  gap: 20px;
}

[data-rk] .ju367v1x {
  gap: 24px;
}

[data-rk] .ju367v1y {
  gap: 28px;
}

[data-rk] .ju367v1z {
  gap: 32px;
}

[data-rk] .ju367v20 {
  gap: 36px;
}

[data-rk] .ju367v21 {
  gap: 44px;
}

[data-rk] .ju367v22 {
  gap: 64px;
}

[data-rk] .ju367v23 {
  gap: -1px;
}

[data-rk] .ju367v24 {
  height: 1px;
}

[data-rk] .ju367v25 {
  height: 2px;
}

[data-rk] .ju367v26 {
  height: 4px;
}

[data-rk] .ju367v27 {
  height: 8px;
}

[data-rk] .ju367v28 {
  height: 12px;
}

[data-rk] .ju367v29 {
  height: 20px;
}

[data-rk] .ju367v2a {
  height: 24px;
}

[data-rk] .ju367v2b {
  height: 28px;
}

[data-rk] .ju367v2c {
  height: 30px;
}

[data-rk] .ju367v2d {
  height: 32px;
}

[data-rk] .ju367v2e {
  height: 34px;
}

[data-rk] .ju367v2f {
  height: 36px;
}

[data-rk] .ju367v2g {
  height: 40px;
}

[data-rk] .ju367v2h {
  height: 44px;
}

[data-rk] .ju367v2i {
  height: 48px;
}

[data-rk] .ju367v2j {
  height: 54px;
}

[data-rk] .ju367v2k {
  height: 60px;
}

[data-rk] .ju367v2l {
  height: 200px;
}

[data-rk] .ju367v2m {
  height: 100%;
}

[data-rk] .ju367v2n {
  height: max-content;
}

[data-rk] .ju367v2o {
  justify-content: flex-start;
}

[data-rk] .ju367v2p {
  justify-content: flex-end;
}

[data-rk] .ju367v2q {
  justify-content: center;
}

[data-rk] .ju367v2r {
  justify-content: space-between;
}

[data-rk] .ju367v2s {
  justify-content: space-around;
}

[data-rk] .ju367v2t {
  text-align: left;
}

[data-rk] .ju367v2u {
  text-align: center;
}

[data-rk] .ju367v2v {
  text-align: inherit;
}

[data-rk] .ju367v2w {
  margin-bottom: 0;
}

[data-rk] .ju367v2x {
  margin-bottom: 1px;
}

[data-rk] .ju367v2y {
  margin-bottom: 2px;
}

[data-rk] .ju367v2z {
  margin-bottom: 3px;
}

[data-rk] .ju367v30 {
  margin-bottom: 4px;
}

[data-rk] .ju367v31 {
  margin-bottom: 5px;
}

[data-rk] .ju367v32 {
  margin-bottom: 6px;
}

[data-rk] .ju367v33 {
  margin-bottom: 8px;
}

[data-rk] .ju367v34 {
  margin-bottom: 10px;
}

[data-rk] .ju367v35 {
  margin-bottom: 12px;
}

[data-rk] .ju367v36 {
  margin-bottom: 14px;
}

[data-rk] .ju367v37 {
  margin-bottom: 16px;
}

[data-rk] .ju367v38 {
  margin-bottom: 18px;
}

[data-rk] .ju367v39 {
  margin-bottom: 20px;
}

[data-rk] .ju367v3a {
  margin-bottom: 24px;
}

[data-rk] .ju367v3b {
  margin-bottom: 28px;
}

[data-rk] .ju367v3c {
  margin-bottom: 32px;
}

[data-rk] .ju367v3d {
  margin-bottom: 36px;
}

[data-rk] .ju367v3e {
  margin-bottom: 44px;
}

[data-rk] .ju367v3f {
  margin-bottom: 64px;
}

[data-rk] .ju367v3g {
  margin-bottom: -1px;
}

[data-rk] .ju367v3h {
  margin-left: 0;
}

[data-rk] .ju367v3i {
  margin-left: 1px;
}

[data-rk] .ju367v3j {
  margin-left: 2px;
}

[data-rk] .ju367v3k {
  margin-left: 3px;
}

[data-rk] .ju367v3l {
  margin-left: 4px;
}

[data-rk] .ju367v3m {
  margin-left: 5px;
}

[data-rk] .ju367v3n {
  margin-left: 6px;
}

[data-rk] .ju367v3o {
  margin-left: 8px;
}

[data-rk] .ju367v3p {
  margin-left: 10px;
}

[data-rk] .ju367v3q {
  margin-left: 12px;
}

[data-rk] .ju367v3r {
  margin-left: 14px;
}

[data-rk] .ju367v3s {
  margin-left: 16px;
}

[data-rk] .ju367v3t {
  margin-left: 18px;
}

[data-rk] .ju367v3u {
  margin-left: 20px;
}

[data-rk] .ju367v3v {
  margin-left: 24px;
}

[data-rk] .ju367v3w {
  margin-left: 28px;
}

[data-rk] .ju367v3x {
  margin-left: 32px;
}

[data-rk] .ju367v3y {
  margin-left: 36px;
}

[data-rk] .ju367v3z {
  margin-left: 44px;
}

[data-rk] .ju367v40 {
  margin-left: 64px;
}

[data-rk] .ju367v41 {
  margin-left: -1px;
}

[data-rk] .ju367v42 {
  margin-right: 0;
}

[data-rk] .ju367v43 {
  margin-right: 1px;
}

[data-rk] .ju367v44 {
  margin-right: 2px;
}

[data-rk] .ju367v45 {
  margin-right: 3px;
}

[data-rk] .ju367v46 {
  margin-right: 4px;
}

[data-rk] .ju367v47 {
  margin-right: 5px;
}

[data-rk] .ju367v48 {
  margin-right: 6px;
}

[data-rk] .ju367v49 {
  margin-right: 8px;
}

[data-rk] .ju367v4a {
  margin-right: 10px;
}

[data-rk] .ju367v4b {
  margin-right: 12px;
}

[data-rk] .ju367v4c {
  margin-right: 14px;
}

[data-rk] .ju367v4d {
  margin-right: 16px;
}

[data-rk] .ju367v4e {
  margin-right: 18px;
}

[data-rk] .ju367v4f {
  margin-right: 20px;
}

[data-rk] .ju367v4g {
  margin-right: 24px;
}

[data-rk] .ju367v4h {
  margin-right: 28px;
}

[data-rk] .ju367v4i {
  margin-right: 32px;
}

[data-rk] .ju367v4j {
  margin-right: 36px;
}

[data-rk] .ju367v4k {
  margin-right: 44px;
}

[data-rk] .ju367v4l {
  margin-right: 64px;
}

[data-rk] .ju367v4m {
  margin-right: -1px;
}

[data-rk] .ju367v4n {
  margin-top: 0;
}

[data-rk] .ju367v4o {
  margin-top: 1px;
}

[data-rk] .ju367v4p {
  margin-top: 2px;
}

[data-rk] .ju367v4q {
  margin-top: 3px;
}

[data-rk] .ju367v4r {
  margin-top: 4px;
}

[data-rk] .ju367v4s {
  margin-top: 5px;
}

[data-rk] .ju367v4t {
  margin-top: 6px;
}

[data-rk] .ju367v4u {
  margin-top: 8px;
}

[data-rk] .ju367v4v {
  margin-top: 10px;
}

[data-rk] .ju367v4w {
  margin-top: 12px;
}

[data-rk] .ju367v4x {
  margin-top: 14px;
}

[data-rk] .ju367v4y {
  margin-top: 16px;
}

[data-rk] .ju367v4z {
  margin-top: 18px;
}

[data-rk] .ju367v50 {
  margin-top: 20px;
}

[data-rk] .ju367v51 {
  margin-top: 24px;
}

[data-rk] .ju367v52 {
  margin-top: 28px;
}

[data-rk] .ju367v53 {
  margin-top: 32px;
}

[data-rk] .ju367v54 {
  margin-top: 36px;
}

[data-rk] .ju367v55 {
  margin-top: 44px;
}

[data-rk] .ju367v56 {
  margin-top: 64px;
}

[data-rk] .ju367v57 {
  margin-top: -1px;
}

[data-rk] .ju367v58 {
  max-width: 1px;
}

[data-rk] .ju367v59 {
  max-width: 2px;
}

[data-rk] .ju367v5a {
  max-width: 4px;
}

[data-rk] .ju367v5b {
  max-width: 8px;
}

[data-rk] .ju367v5c {
  max-width: 12px;
}

[data-rk] .ju367v5d {
  max-width: 20px;
}

[data-rk] .ju367v5e {
  max-width: 24px;
}

[data-rk] .ju367v5f {
  max-width: 28px;
}

[data-rk] .ju367v5g {
  max-width: 30px;
}

[data-rk] .ju367v5h {
  max-width: 32px;
}

[data-rk] .ju367v5i {
  max-width: 34px;
}

[data-rk] .ju367v5j {
  max-width: 36px;
}

[data-rk] .ju367v5k {
  max-width: 40px;
}

[data-rk] .ju367v5l {
  max-width: 44px;
}

[data-rk] .ju367v5m {
  max-width: 48px;
}

[data-rk] .ju367v5n {
  max-width: 54px;
}

[data-rk] .ju367v5o {
  max-width: 60px;
}

[data-rk] .ju367v5p {
  max-width: 200px;
}

[data-rk] .ju367v5q {
  max-width: 100%;
}

[data-rk] .ju367v5r {
  max-width: max-content;
}

[data-rk] .ju367v5s {
  min-width: 1px;
}

[data-rk] .ju367v5t {
  min-width: 2px;
}

[data-rk] .ju367v5u {
  min-width: 4px;
}

[data-rk] .ju367v5v {
  min-width: 8px;
}

[data-rk] .ju367v5w {
  min-width: 12px;
}

[data-rk] .ju367v5x {
  min-width: 20px;
}

[data-rk] .ju367v5y {
  min-width: 24px;
}

[data-rk] .ju367v5z {
  min-width: 28px;
}

[data-rk] .ju367v60 {
  min-width: 30px;
}

[data-rk] .ju367v61 {
  min-width: 32px;
}

[data-rk] .ju367v62 {
  min-width: 34px;
}

[data-rk] .ju367v63 {
  min-width: 36px;
}

[data-rk] .ju367v64 {
  min-width: 40px;
}

[data-rk] .ju367v65 {
  min-width: 44px;
}

[data-rk] .ju367v66 {
  min-width: 48px;
}

[data-rk] .ju367v67 {
  min-width: 54px;
}

[data-rk] .ju367v68 {
  min-width: 60px;
}

[data-rk] .ju367v69 {
  min-width: 200px;
}

[data-rk] .ju367v6a {
  min-width: 100%;
}

[data-rk] .ju367v6b {
  min-width: max-content;
}

[data-rk] .ju367v6c {
  overflow: hidden;
}

[data-rk] .ju367v6d {
  padding-bottom: 0;
}

[data-rk] .ju367v6e {
  padding-bottom: 1px;
}

[data-rk] .ju367v6f {
  padding-bottom: 2px;
}

[data-rk] .ju367v6g {
  padding-bottom: 3px;
}

[data-rk] .ju367v6h {
  padding-bottom: 4px;
}

[data-rk] .ju367v6i {
  padding-bottom: 5px;
}

[data-rk] .ju367v6j {
  padding-bottom: 6px;
}

[data-rk] .ju367v6k {
  padding-bottom: 8px;
}

[data-rk] .ju367v6l {
  padding-bottom: 10px;
}

[data-rk] .ju367v6m {
  padding-bottom: 12px;
}

[data-rk] .ju367v6n {
  padding-bottom: 14px;
}

[data-rk] .ju367v6o {
  padding-bottom: 16px;
}

[data-rk] .ju367v6p {
  padding-bottom: 18px;
}

[data-rk] .ju367v6q {
  padding-bottom: 20px;
}

[data-rk] .ju367v6r {
  padding-bottom: 24px;
}

[data-rk] .ju367v6s {
  padding-bottom: 28px;
}

[data-rk] .ju367v6t {
  padding-bottom: 32px;
}

[data-rk] .ju367v6u {
  padding-bottom: 36px;
}

[data-rk] .ju367v6v {
  padding-bottom: 44px;
}

[data-rk] .ju367v6w {
  padding-bottom: 64px;
}

[data-rk] .ju367v6x {
  padding-bottom: -1px;
}

[data-rk] .ju367v6y {
  padding-left: 0;
}

[data-rk] .ju367v6z {
  padding-left: 1px;
}

[data-rk] .ju367v70 {
  padding-left: 2px;
}

[data-rk] .ju367v71 {
  padding-left: 3px;
}

[data-rk] .ju367v72 {
  padding-left: 4px;
}

[data-rk] .ju367v73 {
  padding-left: 5px;
}

[data-rk] .ju367v74 {
  padding-left: 6px;
}

[data-rk] .ju367v75 {
  padding-left: 8px;
}

[data-rk] .ju367v76 {
  padding-left: 10px;
}

[data-rk] .ju367v77 {
  padding-left: 12px;
}

[data-rk] .ju367v78 {
  padding-left: 14px;
}

[data-rk] .ju367v79 {
  padding-left: 16px;
}

[data-rk] .ju367v7a {
  padding-left: 18px;
}

[data-rk] .ju367v7b {
  padding-left: 20px;
}

[data-rk] .ju367v7c {
  padding-left: 24px;
}

[data-rk] .ju367v7d {
  padding-left: 28px;
}

[data-rk] .ju367v7e {
  padding-left: 32px;
}

[data-rk] .ju367v7f {
  padding-left: 36px;
}

[data-rk] .ju367v7g {
  padding-left: 44px;
}

[data-rk] .ju367v7h {
  padding-left: 64px;
}

[data-rk] .ju367v7i {
  padding-left: -1px;
}

[data-rk] .ju367v7j {
  padding-right: 0;
}

[data-rk] .ju367v7k {
  padding-right: 1px;
}

[data-rk] .ju367v7l {
  padding-right: 2px;
}

[data-rk] .ju367v7m {
  padding-right: 3px;
}

[data-rk] .ju367v7n {
  padding-right: 4px;
}

[data-rk] .ju367v7o {
  padding-right: 5px;
}

[data-rk] .ju367v7p {
  padding-right: 6px;
}

[data-rk] .ju367v7q {
  padding-right: 8px;
}

[data-rk] .ju367v7r {
  padding-right: 10px;
}

[data-rk] .ju367v7s {
  padding-right: 12px;
}

[data-rk] .ju367v7t {
  padding-right: 14px;
}

[data-rk] .ju367v7u {
  padding-right: 16px;
}

[data-rk] .ju367v7v {
  padding-right: 18px;
}

[data-rk] .ju367v7w {
  padding-right: 20px;
}

[data-rk] .ju367v7x {
  padding-right: 24px;
}

[data-rk] .ju367v7y {
  padding-right: 28px;
}

[data-rk] .ju367v7z {
  padding-right: 32px;
}

[data-rk] .ju367v80 {
  padding-right: 36px;
}

[data-rk] .ju367v81 {
  padding-right: 44px;
}

[data-rk] .ju367v82 {
  padding-right: 64px;
}

[data-rk] .ju367v83 {
  padding-right: -1px;
}

[data-rk] .ju367v84 {
  padding-top: 0;
}

[data-rk] .ju367v85 {
  padding-top: 1px;
}

[data-rk] .ju367v86 {
  padding-top: 2px;
}

[data-rk] .ju367v87 {
  padding-top: 3px;
}

[data-rk] .ju367v88 {
  padding-top: 4px;
}

[data-rk] .ju367v89 {
  padding-top: 5px;
}

[data-rk] .ju367v8a {
  padding-top: 6px;
}

[data-rk] .ju367v8b {
  padding-top: 8px;
}

[data-rk] .ju367v8c {
  padding-top: 10px;
}

[data-rk] .ju367v8d {
  padding-top: 12px;
}

[data-rk] .ju367v8e {
  padding-top: 14px;
}

[data-rk] .ju367v8f {
  padding-top: 16px;
}

[data-rk] .ju367v8g {
  padding-top: 18px;
}

[data-rk] .ju367v8h {
  padding-top: 20px;
}

[data-rk] .ju367v8i {
  padding-top: 24px;
}

[data-rk] .ju367v8j {
  padding-top: 28px;
}

[data-rk] .ju367v8k {
  padding-top: 32px;
}

[data-rk] .ju367v8l {
  padding-top: 36px;
}

[data-rk] .ju367v8m {
  padding-top: 44px;
}

[data-rk] .ju367v8n {
  padding-top: 64px;
}

[data-rk] .ju367v8o {
  padding-top: -1px;
}

[data-rk] .ju367v8p {
  position: absolute;
}

[data-rk] .ju367v8q {
  position: fixed;
}

[data-rk] .ju367v8r {
  position: relative;
}

[data-rk] .ju367v8s {
  -webkit-user-select: none;
}

[data-rk] .ju367v8t {
  right: 0;
}

[data-rk] .ju367v8u {
  transition: all .125s;
}

[data-rk] .ju367v8v {
  transition: transform .125s;
}

[data-rk] .ju367v8w {
  -webkit-user-select: none;
  user-select: none;
}

[data-rk] .ju367v8x {
  width: 1px;
}

[data-rk] .ju367v8y {
  width: 2px;
}

[data-rk] .ju367v8z {
  width: 4px;
}

[data-rk] .ju367v90 {
  width: 8px;
}

[data-rk] .ju367v91 {
  width: 12px;
}

[data-rk] .ju367v92 {
  width: 20px;
}

[data-rk] .ju367v93 {
  width: 24px;
}

[data-rk] .ju367v94 {
  width: 28px;
}

[data-rk] .ju367v95 {
  width: 30px;
}

[data-rk] .ju367v96 {
  width: 32px;
}

[data-rk] .ju367v97 {
  width: 34px;
}

[data-rk] .ju367v98 {
  width: 36px;
}

[data-rk] .ju367v99 {
  width: 40px;
}

[data-rk] .ju367v9a {
  width: 44px;
}

[data-rk] .ju367v9b {
  width: 48px;
}

[data-rk] .ju367v9c {
  width: 54px;
}

[data-rk] .ju367v9d {
  width: 60px;
}

[data-rk] .ju367v9e {
  width: 200px;
}

[data-rk] .ju367v9f {
  width: 100%;
}

[data-rk] .ju367v9g {
  width: max-content;
}

[data-rk] .ju367v9h {
  backdrop-filter: var(--rk-blurs-modalOverlay);
}

[data-rk] .ju367v9i, [data-rk] .ju367v9j:hover, [data-rk] .ju367v9k:active {
  background: var(--rk-colors-accentColor);
}

[data-rk] .ju367v9l, [data-rk] .ju367v9m:hover, [data-rk] .ju367v9n:active {
  background: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367v9o, [data-rk] .ju367v9p:hover, [data-rk] .ju367v9q:active {
  background: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367v9r, [data-rk] .ju367v9s:hover, [data-rk] .ju367v9t:active {
  background: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367v9u, [data-rk] .ju367v9v:hover, [data-rk] .ju367v9w:active {
  background: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367v9x, [data-rk] .ju367v9y:hover, [data-rk] .ju367v9z:active {
  background: var(--rk-colors-closeButton);
}

[data-rk] .ju367va0, [data-rk] .ju367va1:hover, [data-rk] .ju367va2:active {
  background: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367va3, [data-rk] .ju367va4:hover, [data-rk] .ju367va5:active {
  background: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367va6, [data-rk] .ju367va7:hover, [data-rk] .ju367va8:active {
  background: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367va9, [data-rk] .ju367vaa:hover, [data-rk] .ju367vab:active {
  background: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vac, [data-rk] .ju367vad:hover, [data-rk] .ju367vae:active {
  background: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vaf, [data-rk] .ju367vag:hover, [data-rk] .ju367vah:active {
  background: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vai, [data-rk] .ju367vaj:hover, [data-rk] .ju367vak:active {
  background: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367val, [data-rk] .ju367vam:hover, [data-rk] .ju367van:active {
  background: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vao, [data-rk] .ju367vap:hover, [data-rk] .ju367vaq:active {
  background: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367var, [data-rk] .ju367vas:hover, [data-rk] .ju367vat:active {
  background: var(--rk-colors-error);
}

[data-rk] .ju367vau, [data-rk] .ju367vav:hover, [data-rk] .ju367vaw:active {
  background: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vax, [data-rk] .ju367vay:hover, [data-rk] .ju367vaz:active {
  background: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vb0, [data-rk] .ju367vb1:hover, [data-rk] .ju367vb2:active {
  background: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vb3, [data-rk] .ju367vb4:hover, [data-rk] .ju367vb5:active {
  background: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vb6, [data-rk] .ju367vb7:hover, [data-rk] .ju367vb8:active {
  background: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vb9, [data-rk] .ju367vba:hover, [data-rk] .ju367vbb:active {
  background: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vbc, [data-rk] .ju367vbd:hover, [data-rk] .ju367vbe:active {
  background: var(--rk-colors-modalText);
}

[data-rk] .ju367vbf, [data-rk] .ju367vbg:hover, [data-rk] .ju367vbh:active {
  background: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vbi, [data-rk] .ju367vbj:hover, [data-rk] .ju367vbk:active {
  background: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vbl, [data-rk] .ju367vbm:hover, [data-rk] .ju367vbn:active {
  background: var(--rk-colors-profileAction);
}

[data-rk] .ju367vbo, [data-rk] .ju367vbp:hover, [data-rk] .ju367vbq:active {
  background: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367vbr, [data-rk] .ju367vbs:hover, [data-rk] .ju367vbt:active {
  background: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vbu, [data-rk] .ju367vbv:hover, [data-rk] .ju367vbw:active {
  background: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vbx, [data-rk] .ju367vby:hover, [data-rk] .ju367vbz:active {
  background: var(--rk-colors-standby);
}

[data-rk] .ju367vc0, [data-rk] .ju367vc1:hover, [data-rk] .ju367vc2:active {
  border-color: var(--rk-colors-accentColor);
}

[data-rk] .ju367vc3, [data-rk] .ju367vc4:hover, [data-rk] .ju367vc5:active {
  border-color: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367vc6, [data-rk] .ju367vc7:hover, [data-rk] .ju367vc8:active {
  border-color: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367vc9, [data-rk] .ju367vca:hover, [data-rk] .ju367vcb:active {
  border-color: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367vcc, [data-rk] .ju367vcd:hover, [data-rk] .ju367vce:active {
  border-color: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367vcf, [data-rk] .ju367vcg:hover, [data-rk] .ju367vch:active {
  border-color: var(--rk-colors-closeButton);
}

[data-rk] .ju367vci, [data-rk] .ju367vcj:hover, [data-rk] .ju367vck:active {
  border-color: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367vcl, [data-rk] .ju367vcm:hover, [data-rk] .ju367vcn:active {
  border-color: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367vco, [data-rk] .ju367vcp:hover, [data-rk] .ju367vcq:active {
  border-color: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367vcr, [data-rk] .ju367vcs:hover, [data-rk] .ju367vct:active {
  border-color: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vcu, [data-rk] .ju367vcv:hover, [data-rk] .ju367vcw:active {
  border-color: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vcx, [data-rk] .ju367vcy:hover, [data-rk] .ju367vcz:active {
  border-color: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vd0, [data-rk] .ju367vd1:hover, [data-rk] .ju367vd2:active {
  border-color: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vd3, [data-rk] .ju367vd4:hover, [data-rk] .ju367vd5:active {
  border-color: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vd6, [data-rk] .ju367vd7:hover, [data-rk] .ju367vd8:active {
  border-color: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367vd9, [data-rk] .ju367vda:hover, [data-rk] .ju367vdb:active {
  border-color: var(--rk-colors-error);
}

[data-rk] .ju367vdc, [data-rk] .ju367vdd:hover, [data-rk] .ju367vde:active {
  border-color: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vdf, [data-rk] .ju367vdg:hover, [data-rk] .ju367vdh:active {
  border-color: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vdi, [data-rk] .ju367vdj:hover, [data-rk] .ju367vdk:active {
  border-color: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vdl, [data-rk] .ju367vdm:hover, [data-rk] .ju367vdn:active {
  border-color: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vdo, [data-rk] .ju367vdp:hover, [data-rk] .ju367vdq:active {
  border-color: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vdr, [data-rk] .ju367vds:hover, [data-rk] .ju367vdt:active {
  border-color: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vdu, [data-rk] .ju367vdv:hover, [data-rk] .ju367vdw:active {
  border-color: var(--rk-colors-modalText);
}

[data-rk] .ju367vdx, [data-rk] .ju367vdy:hover, [data-rk] .ju367vdz:active {
  border-color: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367ve0, [data-rk] .ju367ve1:hover, [data-rk] .ju367ve2:active {
  border-color: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367ve3, [data-rk] .ju367ve4:hover, [data-rk] .ju367ve5:active {
  border-color: var(--rk-colors-profileAction);
}

[data-rk] .ju367ve6, [data-rk] .ju367ve7:hover, [data-rk] .ju367ve8:active {
  border-color: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367ve9, [data-rk] .ju367vea:hover, [data-rk] .ju367veb:active {
  border-color: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vec, [data-rk] .ju367ved:hover, [data-rk] .ju367vee:active {
  border-color: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vef, [data-rk] .ju367veg:hover, [data-rk] .ju367veh:active {
  border-color: var(--rk-colors-standby);
}

[data-rk] .ju367vei, [data-rk] .ju367vej:hover, [data-rk] .ju367vek:active {
  box-shadow: var(--rk-shadows-connectButton);
}

[data-rk] .ju367vel, [data-rk] .ju367vem:hover, [data-rk] .ju367ven:active {
  box-shadow: var(--rk-shadows-dialog);
}

[data-rk] .ju367veo, [data-rk] .ju367vep:hover, [data-rk] .ju367veq:active {
  box-shadow: var(--rk-shadows-profileDetailsAction);
}

[data-rk] .ju367ver, [data-rk] .ju367ves:hover, [data-rk] .ju367vet:active {
  box-shadow: var(--rk-shadows-selectedOption);
}

[data-rk] .ju367veu, [data-rk] .ju367vev:hover, [data-rk] .ju367vew:active {
  box-shadow: var(--rk-shadows-selectedWallet);
}

[data-rk] .ju367vex, [data-rk] .ju367vey:hover, [data-rk] .ju367vez:active {
  box-shadow: var(--rk-shadows-walletLogo);
}

[data-rk] .ju367vf0, [data-rk] .ju367vf1:hover, [data-rk] .ju367vf2:active {
  color: var(--rk-colors-accentColor);
}

[data-rk] .ju367vf3, [data-rk] .ju367vf4:hover, [data-rk] .ju367vf5:active {
  color: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367vf6, [data-rk] .ju367vf7:hover, [data-rk] .ju367vf8:active {
  color: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367vf9, [data-rk] .ju367vfa:hover, [data-rk] .ju367vfb:active {
  color: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367vfc, [data-rk] .ju367vfd:hover, [data-rk] .ju367vfe:active {
  color: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367vff, [data-rk] .ju367vfg:hover, [data-rk] .ju367vfh:active {
  color: var(--rk-colors-closeButton);
}

[data-rk] .ju367vfi, [data-rk] .ju367vfj:hover, [data-rk] .ju367vfk:active {
  color: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367vfl, [data-rk] .ju367vfm:hover, [data-rk] .ju367vfn:active {
  color: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367vfo, [data-rk] .ju367vfp:hover, [data-rk] .ju367vfq:active {
  color: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367vfr, [data-rk] .ju367vfs:hover, [data-rk] .ju367vft:active {
  color: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vfu, [data-rk] .ju367vfv:hover, [data-rk] .ju367vfw:active {
  color: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vfx, [data-rk] .ju367vfy:hover, [data-rk] .ju367vfz:active {
  color: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vg0, [data-rk] .ju367vg1:hover, [data-rk] .ju367vg2:active {
  color: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vg3, [data-rk] .ju367vg4:hover, [data-rk] .ju367vg5:active {
  color: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vg6, [data-rk] .ju367vg7:hover, [data-rk] .ju367vg8:active {
  color: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367vg9, [data-rk] .ju367vga:hover, [data-rk] .ju367vgb:active {
  color: var(--rk-colors-error);
}

[data-rk] .ju367vgc, [data-rk] .ju367vgd:hover, [data-rk] .ju367vge:active {
  color: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vgf, [data-rk] .ju367vgg:hover, [data-rk] .ju367vgh:active {
  color: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vgi, [data-rk] .ju367vgj:hover, [data-rk] .ju367vgk:active {
  color: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vgl, [data-rk] .ju367vgm:hover, [data-rk] .ju367vgn:active {
  color: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vgo, [data-rk] .ju367vgp:hover, [data-rk] .ju367vgq:active {
  color: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vgr, [data-rk] .ju367vgs:hover, [data-rk] .ju367vgt:active {
  color: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vgu, [data-rk] .ju367vgv:hover, [data-rk] .ju367vgw:active {
  color: var(--rk-colors-modalText);
}

[data-rk] .ju367vgx, [data-rk] .ju367vgy:hover, [data-rk] .ju367vgz:active {
  color: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vh0, [data-rk] .ju367vh1:hover, [data-rk] .ju367vh2:active {
  color: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vh3, [data-rk] .ju367vh4:hover, [data-rk] .ju367vh5:active {
  color: var(--rk-colors-profileAction);
}

[data-rk] .ju367vh6, [data-rk] .ju367vh7:hover, [data-rk] .ju367vh8:active {
  color: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367vh9, [data-rk] .ju367vha:hover, [data-rk] .ju367vhb:active {
  color: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vhc, [data-rk] .ju367vhd:hover, [data-rk] .ju367vhe:active {
  color: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vhf, [data-rk] .ju367vhg:hover, [data-rk] .ju367vhh:active {
  color: var(--rk-colors-standby);
}

@media screen and (width >= 768px) {
  [data-rk] .ju367v1 {
    align-items: flex-start;
  }

  [data-rk] .ju367v3 {
    align-items: flex-end;
  }

  [data-rk] .ju367v5 {
    align-items: center;
  }

  [data-rk] .ju367v7 {
    display: none;
  }

  [data-rk] .ju367v9 {
    display: block;
  }

  [data-rk] .ju367vb {
    display: flex;
  }

  [data-rk] .ju367vd {
    display: inline;
  }
}

[data-rk] ._12cbo8i3, [data-rk] ._12cbo8i3:after {
  --_12cbo8i0: 1;
  --_12cbo8i1: 1;
}

[data-rk] ._12cbo8i3:hover {
  transform: scale(var(--_12cbo8i0));
}

[data-rk] ._12cbo8i3:active {
  transform: scale(var(--_12cbo8i1));
}

[data-rk] ._12cbo8i3:active:after {
  content: "";
  transform: scale(calc((1 / var(--_12cbo8i1)) * var(--_12cbo8i0)));
  display: block;
  position: absolute;
  inset: -1px;
}

[data-rk] ._12cbo8i4, [data-rk] ._12cbo8i4:after {
  --_12cbo8i0: 1.025;
}

[data-rk] ._12cbo8i5, [data-rk] ._12cbo8i5:after {
  --_12cbo8i0: 1.1;
}

[data-rk] ._12cbo8i6, [data-rk] ._12cbo8i6:after {
  --_12cbo8i1: .95;
}

[data-rk] ._12cbo8i7, [data-rk] ._12cbo8i7:after {
  --_12cbo8i1: .9;
}

@keyframes _1luule41 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

[data-rk] ._1luule42 {
  animation: 3s linear infinite _1luule41;
}

[data-rk] ._1luule43 {
  background: conic-gradient(from 180deg, #4892fe00 0deg, currentColor 282.04deg, #4892fe00 319.86deg 360deg);
  width: 21px;
  height: 21px;
}

@keyframes _9pm4ki0 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes _9pm4ki1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

[data-rk] ._9pm4ki3 {
  z-index: 2147483646;
  padding: 200px;
  animation: .15s _9pm4ki1;
  inset: -200px;
  transform: translateZ(0);
}

[data-rk] ._9pm4ki5 {
  max-width: 100vw;
  animation: .35s cubic-bezier(.15, 1.15, .6, 1) _9pm4ki0, .15s _9pm4ki1;
}

[data-rk] ._1ckjpok1 {
  box-sizing: content-box;
  width: 360px;
  max-width: 100vw;
}

[data-rk] ._1ckjpok2 {
  width: 100vw;
}

[data-rk] ._1ckjpok3 {
  width: 720px;
  min-width: 720px;
}

[data-rk] ._1ckjpok4 {
  width: 368px;
  min-width: 368px;
}

[data-rk] ._1ckjpok6 {
  box-sizing: border-box;
  border-width: 0;
  width: 100vw;
}

@media screen and (width >= 768px) {
  [data-rk] ._1ckjpok1 {
    width: 360px;
  }

  [data-rk] ._1ckjpok2 {
    width: 480px;
  }

  [data-rk] ._1ckjpok4 {
    width: 368px;
    min-width: 368px;
  }
}

@media screen and (width <= 767px) {
  [data-rk] ._1ckjpok7 {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: -200px;
    padding-bottom: 200px;
    top: 200px;
  }
}

[data-rk] .v9horb0:hover {
  background: unset;
}

[data-rk] ._18dqw9x0 {
  max-height: 456px;
  overflow: hidden auto;
}

[data-rk] ._18dqw9x1 {
  scrollbar-width: none;
  max-height: 456px;
  overflow: hidden auto;
}

[data-rk] ._18dqw9x1::-webkit-scrollbar {
  display: none;
}

[data-rk] .g5kl0l0 {
  border-color: #0000;
}

[data-rk] ._1vwt0cg0 {
  background: #fff;
}

[data-rk] ._1vwt0cg2 {
  max-height: 454px;
  overflow-y: auto;
}

[data-rk] ._1vwt0cg3 {
  min-width: 287px;
}

[data-rk] ._1vwt0cg4 {
  min-width: 100%;
}

@keyframes _1am14411 {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -283px;
  }
}

[data-rk] ._1am14410 {
  scrollbar-width: none;
  overflow: auto;
  transform: translateZ(0);
}

[data-rk] ._1am14410::-webkit-scrollbar {
  display: none;
}

[data-rk] ._1am14412 {
  stroke-dasharray: 98 196;
  fill: none;
  stroke-linecap: round;
  stroke-width: 4px;
  animation: 1s linear infinite _1am14411;
}

[data-rk] ._1am14413 {
  position: absolute;
}

[data-rk] ._1y2lnfi0 {
  border: 1px solid #10151f0f;
}

[data-rk] ._1y2lnfi1 {
  max-width: fit-content;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mb-px {
  margin-bottom: -1px;
}

.-mt-px {
  margin-top: -1px;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.max-h-48 {
  max-height: 12rem;
}

.min-h-full {
  min-height: 100%;
}

.w-0 {
  width: 0;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-lg {
  max-width: 32rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-px {
  gap: 1px;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-900\/5 {
  background-color: #1118270d;
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.p-1 {
  padding: .25rem;
}

.p-4 {
  padding: 1rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-gray-300 {
  text-decoration-color: #d1d5db;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-cyan-100 {
  --tw-shadow-color: #cffafe;
  --tw-shadow: var(--tw-shadow-colored);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

body {
  font-family: Roboto, sans-serif;
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-blue-600:focus-visible {
  outline-color: #2563eb;
}

.focus-visible\:outline-green-600:focus-visible {
  outline-color: #16a34a;
}

.focus-visible\:outline-indigo-600:focus-visible {
  outline-color: #4f46e5;
}

.focus-visible\:outline-red-600:focus-visible {
  outline-color: #dc2626;
}

@media (width >= 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 768px) {
  .md\:-mt-px {
    margin-top: -1px;
  }

  .md\:flex {
    display: flex;
  }
}

@media (width >= 1024px) {
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
/*# sourceMappingURL=index.6125c0b8.css.map */
